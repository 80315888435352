import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { getState } from '../../store';
import * as searchPaths from '../../constants/search-paths';
import { SEARCH_PAGE_SIZE } from '../../constants/paginated-page-sizes';
import { createFetchResults } from '../../common/util/create-fetch-results';
import { SORTING_ORDER_ASCENDING, SORTING_ORDER_DESCENDING, SORT_DOWNLOADS } from '../../constants/search';

// Components
import SearchResultsWrapper from './results/wrapper';
import NoResult from '../common/no-result';
import PreviewCardWrapper from '../resources/preview-card-wrapper';
import ListRow from './results/list-row';
import { getVisibleHeaders } from './results/list-headers';

// Stores
import ResultsStore from '../../stores/results';

export default class Search extends React.Component {
  static displayName = 'Search'

  static propTypes = {
    account: PropTypes.object,
    results: PropTypes.object,
    router: PropTypes.object,
    session: PropTypes.object,
    query: PropTypes.string,
    sortBy: PropTypes.string,
    sortOrder: PropTypes.oneOf([SORTING_ORDER_ASCENDING, SORTING_ORDER_DESCENDING]),
    defaultSortBy: PropTypes.string,
    defaultSortOrder: PropTypes.oneOf([SORTING_ORDER_ASCENDING, SORTING_ORDER_DESCENDING]),
  }

  onCardRender (resource) {
    return (
      <PreviewCardWrapper
        accountSettings={this.props.account.settings}
        allowApprovedContent={this.props.session.allowApprovedContent}
        accountId={this.props.session.accountId}
        available
        resource={resource}
      />
    );
  }

  onListItemRender (resource, responsiveProps, isDesktop) {
    return (
      <ListRow
        key={resource.id}
        session={this.props.session}
        resource={resource}
        isFavorite={!!this.props.favorites.resourcesByResourceId[resource.id]}
        accountSettings={this.props.account.settings}
        allowApprovedContent={this.props.session.allowApprovedContent}
        accountId={this.props.session.accountId}
        isDesktop={isDesktop}
        featureFlags={this.props.featureFlags}
        {...responsiveProps}
      />
    );
  }

  render () {
    const state = getState();
    const { results, account, session, defaultSortBy, defaultSortOrder } = this.props;
    const noResultsMessage = results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Resources To Show');

    const stateQuery = state.router.query;

    const isFeaturedByQuery = !stateQuery.q && (stateQuery.sortBy === SORT_DOWNLOADS || !stateQuery.sortBy);
    const showGlobalFeaturedContent = state.account.settings.canShowFeatured && isFeaturedByQuery;

    let baseParams;
    if (showGlobalFeaturedContent || isFeaturedByQuery) {
      baseParams = { exportStatus: 'complete', showGlobalFeaturedContent, prioritizeFeaturedContent: isFeaturedByQuery };
    } else {
      baseParams = { exportStatus: 'complete' };
    }

    const fetchResults = createFetchResults(searchPaths.RESOURCES, SEARCH_PAGE_SIZE, { defaultSortBy, defaultSortOrder });

    return (
      <SearchResultsWrapper
        baseParams={baseParams}
        fetchResults={fetchResults}
        resultsPerPage={SEARCH_PAGE_SIZE}
        isLoading={ResultsStore.showLoading()}
        queryArialLabel={formatMessage(
          'Search Commons for course resources by tag, name, institution, or outcome'
        )}
        searchPrivateObjects
        emptyMessage={<NoResult title={noResultsMessage} />}
        canShowPublic={
          account.settings.canShowPublic && session.sid && !session.publicOnly
        }
        canToggleLayout
        onCardRender={resource => this.onCardRender(resource)}
        onListItemRender={(resource, responsiveProps, isDesktop) => this.onListItemRender(resource, responsiveProps, isDesktop)}
        onGetListHeaders={(isDesktop) => getVisibleHeaders(isDesktop)}
        layout={state.localStorage.searchPage.resultsView}
        {...this.props}
      />
    );
  }
}
