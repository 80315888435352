import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import AppHandler from '../components/app-handler';
import SearchOptions from '../components/search/search-options';
import FavoritesHandler from '../components/search/favorites-handler';
import {
  FavoritesActions,
  SessionActions,
  SearchActions,
  RouterActions
} from '../actions';
import { getState, subscribe } from '../store';
import PageTitle from '../components/common/page-title';
import { getDefaultSearchSortingOptions } from '../components/search/sorting/default-search-sorting-options';
import { getSortingFromQuery } from './util/get-sorting-from-query';
import { isCursorLimitReachedError } from '../common/is-cursor-limit-reached-error';

/* eslint-disable react/prop-types */
const View = ({ queryParams }) => {
  const {
    q,
    types,
    scopeIds,
    gradeIds,
    subjectIds,
    contentCategoryTypes
  } = queryParams;
  const { sortBy, sortOrder } = getSortingFromQuery(queryParams);
  const state = getState();

  return (
    <AppHandler
      activeHeader="favorites"
      session={state.session}
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <div className="responsive-results-width">
        <Head title="Favorites" />
        <PageTitle title={formatMessage('Favorites')} />
        <main className="Main">
          <SearchOptions
            featureFlags={state.featureFlags}
            searchFavoriteObjects
            onChange={SearchActions.update}
            query={q}
            types={types ? types.split(',') : []}
            scopeIds={scopeIds ? scopeIds.split(',') : []}
            gradeIds={gradeIds ? gradeIds.split(',') : []}
            contentCategoryTypes={contentCategoryTypes ? contentCategoryTypes.split(',') : []}
            subjectIds={subjectIds}
            sortBy={sortBy}
            sortOrder={sortOrder}
            sortingOptions={getDefaultSearchSortingOptions()}
            consortiums={state.consortiums}
            groups={state.groups.list}
            canShowApprovedContent={state.account.settings.allowApprovedContent}
            canShowPublic={
              state.account.settings.canShowPublic &&
              state.session.sid &&
              !state.session.publicOnly
            }
            queryArialLabel={formatMessage(
              'Filter Favorite resources by tag, name, institution, or outcome'
            )}
            account={state.session.account}
            showFilters={state.filter.showFilterTray}
            disableSorting={state.results && isCursorLimitReachedError(state.results.error)}
          />
          <FavoritesHandler {...getState()} sortBy={sortBy} sortOrder={sortOrder} />
        </main>
      </div>
    </AppHandler>
  );
};

export default function ({ resolve, location, render, exiting }) {
  RouterActions.backTo(formatMessage('Favorites'), window.location.href);

  const favoritesState = getState().favorites;
  const isLoading = favoritesState.listIsLoading;
  const isAlreadyLoaded = favoritesState.listIsLoaded;

  if (!isAlreadyLoaded && !isLoading) {
    setTimeout(() => {
      FavoritesActions.loadList();
    }, 0);
  }

  const unsubscribe = subscribe(() => render(<View queryParams={location.query} />));
  exiting.then(unsubscribe);
  render(<View queryParams={location.query} />);
  resolve();
}
