import { SORT_MATCHING, SORT_RECENT, SORTING_ORDER_DESCENDING } from '../../constants/search';

export const getSortingFromQuery = ({ q, sortBy, sortOrder }, options = {}) => {
  const defaultSortBy = options.defaultSortBy || SORT_RECENT;
  const defaultSortOrder = options.defaultSortOrder || SORTING_ORDER_DESCENDING;
  return {
    sortBy: sortBy || (q ? SORT_MATCHING : defaultSortBy),
    sortOrder: sortOrder || defaultSortOrder,
  };
};
